export const shipmentObj = {
  shipment_id: null,
  shipment_type: null,
  shipment_type_name: "",
  shipment_status: "",
  shipper: null,
  shipper_reference_number: "",
  quote_amount: null,
  quote_approved_yn: false,
  estimated_delivery_date: null,
  received_date: null,
  pickup_date: null,
  loading_date: null,
  shipment_date: null,
  shipper_invoice_number: "",
  shipper_invoice_amount: null,
  created_by: null,
  created_at: "",
  modified_by: null,
  modified_at: "",
  supplier: null,
  mcp_po_number: "",
  customer: null,
  mcp_so_number: "",
  customer_po_number: "",
  customer_received_date: null,
  customer_proof_of_delivery_received_yn: false,
};
