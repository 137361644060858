<template>
	<div
		v-if="!showLoader"
		class=""
	>
		<m-page-title title="Shipments" />
		<b-row class="mt-2 align-items-center">
			<b-colxx
				md="2"
				sm="12"
			>
				<b-form-group
					id="search-shipment-type"
					label="Search Type"
					label-for="type-search"
					class="field-label-form-group mt-2"
				>
					<b-form-select
						id="type-searcj"
						v-model="searchType"
						:options="shipmentEntityTypes"
						value-field="entity_type_code_id"
						text-field="type_label"
					></b-form-select>
				</b-form-group>
			</b-colxx>
			<b-colxx
				md="2"
				sm="12"
			>
				<b-form-group
					id="search-shipment-status"
					label="Search Status"
					label-for="status-search"
					class="field-label-form-group mt-2"
				>
					<b-form-select
						id="status-search"
						v-model="searchStatus"
						:options="shipmentStatus"
					></b-form-select>
				</b-form-group>
			</b-colxx>
			<b-colxx
				md="2"
				sm="12"
			>
				<b-form-group
					id="search-shipper"
					label="Search Shipper"
					label-for="shipper-search"
					class="field-label-form-group mt-2"
				>
					<b-form-select
						id="shipper-search"
						v-model="searchShipper"
						:options="shipperList"
						value-field="shipper_id"
						text-field="shipper_name"
					></b-form-select>
				</b-form-group>
			</b-colxx>
			<b-colxx
				md="2"
				sm="12"
			>
				<b-form-group
					id="filter-closed"
					label="Hide Closed"
					label-for="filter-hide-closed"
					class="field-label-form-group mt-2"
				>
					<b-form-checkbox
						id="filter-hide-closed"
						switch
						class="ml-5"
						v-model="filterHideClosed"
					></b-form-checkbox>
				</b-form-group>
			</b-colxx>
			<b-colxx
				md="4"
				sm="12"
			>
				<b-button
					variant="success"
					@click="getShipmentList()"
					>Search</b-button
				>
				<b-button
					variant="info"
					class="ml-1"
					@click="clearSearch()"
					>Clear</b-button
				>
				<b-button
					v-if="permissions.includes('CanCreateLogisticsRecord')"
					variant="warning"
					class="ml-1"
					@click="createShipment()"
					>New</b-button
				>
			</b-colxx>
		</b-row>
		<b-row class="mt-2">
			<b-colxx
				md="12"
				sm="12"
			>
				<b-row class="my-4">
					<b-colxx
						md="12"
						sm="12"
					>
						<b-table
							id="shipment-table"
							ref="__shipmentTable"
							responsive
							head-variant="dark"
							:items="filteredShipmentList"
							:fields="shipment_list_fields"
							:current-page="current_page"
							:busy="shipmentTableBusy"
							per-page="0"
						>
							<template #cell(actions)="row">
								<i
									v-if="permissions.includes('CanViewLogistics')"
									class="fas fa-eye text-primary icon-pointer"
									@click="goShipmentDetail(row.item, row.index)"
								/>
								<i
									v-if="permissions.includes('CanEditLogisticsRecord')"
									class="fas fa-note-medical text-default icon-pointer ml-1"
									@click="openNote(row.item, row.index)"
								/>
							</template>
						</b-table>
						<b-pagination
							v-model="current_page"
							:total-rows="total_rows"
							:per-page="per_page"
							:number-of-pages="pageTotal"
							size="sm"
							class="my-0"
							aria-controls="shipment-table"
							@input="goShipmentDetail()"
						></b-pagination>
					</b-colxx>
				</b-row>
			</b-colxx>
		</b-row>

		<!--  -->
		<!-- View/Create Shipment Modal -->
		<!--  -->
		<div id="shipment-detail-modal-div">
			<validation-observer
				ref="__validateShipmentForm"
				v-slot="observer"
			>
				<b-modal
					id="shipment-detail-modal"
					v-model="showShipmentModal"
					title="Shipment Details"
					centered
					scrollable
					no-close-on-esc
					no-close-on-backdrop
					hide-header-close
					header-bg-variant="info"
					header-text-variant="white"
					size="xl"
				>
					<b-row class="my-1">
						<b-alert
							v-model="showAlert"
							:variant="alertVariant"
							class="w-100"
							dismissible
							>{{ alertMessage }}</b-alert
						>
					</b-row>
					<b-form-row class="my-1">
						<b-colxx
							md="12"
							sm="12"
						>
							<validation-provider
								rules="required"
								name="Shipment Type"
								v-bind="$attrs"
								v-slot="{ errors }"
							>
								<b-form-group
									id="shipment-type"
									label="Select Type"
									label-for="type-select"
									class="field-label-form-group mt-2"
								>
									<b-form-select
										id="type-select"
										v-model="shipmentDetail.shipment_type"
										:options="shipmentEntityTypes"
										value-field="entity_type_code_id"
										text-field="type_label"
										:disabled="
											editShipmentYN &&
											!permissions.includes('CanEditLogisticsRecord')
										"
										@change="getShipmentType()"
									></b-form-select>
								</b-form-group>
								<div
									v-if="errors[0]"
									class="invalid-feedback text-xs"
									style="display: inline-block"
								>
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-colxx>
					</b-form-row>
					<b-form-row class="my-1">
						<b-colxx
							md="3"
							sm="12"
						>
							<validation-provider
								rules="required"
								name="Shipment Status"
								v-bind="$attrs"
								v-slot="{ errors }"
							>
								<b-form-group
									id="shipment-status"
									label="Status"
									label-for="status-select"
									class="field-label-form-group mt-2"
								>
									<b-form-select
										id="status-select"
										:disabled="!permissions.includes('CanEditLogisticsRecord')"
										v-model="shipmentDetail.shipment_status"
										:options="shipmentStatus"
									></b-form-select>
								</b-form-group>
								<div
									v-if="errors[0]"
									class="invalid-feedback text-xs"
									style="display: inline-block"
								>
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-colxx>

						<b-colxx
							v-if="showSupplierField"
							md="4"
							sm="12"
						>
							<validation-provider
								rules="required"
								name="Supplier"
								v-bind="$attrs"
								v-slot="{ errors }"
							>
								<!-- div vif customer or supplier -->
								<b-form-group
									id="supplier"
									label="Supplier"
									label-for="supplier-select"
									class="field-label-form-group mt-2"
								>
									<b-form-select
										id="supplier-select"
										v-model="shipmentDetail.supplier"
										:options="supplierList"
										:disabled="!permissions.includes('CanEditLogisticsRecord')"
										value-field="erp_supplier_id"
										text-field="name"
									></b-form-select>
								</b-form-group>
								<div
									v-if="errors[0]"
									class="invalid-feedback text-xs"
									style="display: inline-block"
								>
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-colxx>

						<b-colxx
							v-if="showCustomerField"
							md="4"
							sm="12"
						>
							<b-form-group
								id="customer"
								label="Customer Name"
								label-for="status-select"
								class="field-label-form-group mt-2"
							>
								<b-form-input
									v-model.trim="shipmentDetail.customer_name"
									list="customer-options"
									id="search-customer-list"
									name="customer"
									:disabled="!permissions.includes('CanEditLogisticsRecord')"
									placeholder="Start typing to search for customer."
									@input="searchCustomer"
									@change="selectCustomer($event)"
								/>
								<datalist id="customer-options">
									<option
										v-for="customer in searchCustomerResults"
										:key="customer.erp_customer_id"
									>
										{{ customer.customer_name }}
									</option>
								</datalist>
								<div
									v-if="showTemporaryAlert"
									class="warning-message"
								>
									{{ temporaryAlertMessage }}
								</div>
							</b-form-group>
						</b-colxx>

						<b-colxx
							v-if="showMcpPoField"
							md="3"
							sm="12"
						>
							<m-text-input
								id="mcp-po"
								label="MCP PO Number"
								type="text"
								:value="shipmentDetail.mcp_po_number"
								:rules="{ max: 100 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="MCP PO No"
								@update-value="
									(input) => {
										shipmentDetail.mcp_po_number = input;
									}
								"
							/>
						</b-colxx>
						<b-colxx
							v-if="showMcpSoField"
							md="3"
							sm="12"
						>
							<m-text-input
								id="mcp-po"
								label="MCP SO Number"
								type="text"
								:value="shipmentDetail.mcp_so_number"
								:rules="{ max: 100 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="MCP SO No"
								@update-value="
									(input) => {
										shipmentDetail.mcp_so_number = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<b-form-group
								id="shipper"
								label="Shipper"
								label-for="shipper-select"
								class="field-label-form-group mt-2"
							>
								<b-form-select
									id="shipper-select"
									v-model="shipmentDetail.shipper"
									:options="shipperList"
									:disabled="!permissions.includes('CanEditLogisticsRecord')"
									value-field="shipper_id"
									text-field="shipper_name"
								></b-form-select>
							</b-form-group>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="shipper-ref-no"
								label="Shipper Ref #"
								type="text"
								:value="shipmentDetail.shipper_reference_number"
								:rules="{ max: 120 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Shipper Ref No"
								@update-value="
									(input) => {
										shipmentDetail.shipper_reference_number = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="mcp-po"
								label="Quote Amount"
								type="text"
								:value="shipmentDetail.quote_amount"
								:rules="{ max: 10 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Shipping Quote"
								@update-value="
									(input) => {
										shipmentDetail.quote_amount = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<b-form-group
								id="quote-approved"
								label="Quote Approved"
								label-for="quote-approved-yn"
								class="field-label-form-group mt-2"
							>
								<b-form-checkbox
									id="quote-approved-yn"
									switch
									class="ml-5"
									:disabled="!permissions.includes('CanEditLogisticsRecord')"
									v-model="shipmentDetail.quote_approved_yn"
								></b-form-checkbox>
							</b-form-group>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="est-del-date"
								label="Delivery Date (Est.)"
								type="date"
								:value="shipmentDetail.estimated_delivery_date"
								:alternative="false"
								:group="false"
								name="Estimate Delivery Date"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								@update-value="
									(input) => {
										shipmentDetail.estimated_delivery_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="load-date"
								label="Loading Date"
								type="date"
								:value="shipmentDetail.loading_date"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Loading Date"
								@update-value="
									(input) => {
										shipmentDetail.loading_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="pickup-date"
								label="Pickup Date"
								type="date"
								:value="shipmentDetail.pickup_date"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Pickup Date"
								@update-value="
									(input) => {
										shipmentDetail.pickup_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="shipment-date"
								label="Shipment Date"
								type="date"
								:value="shipmentDetail.shipment_date"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Shipment Date"
								@update-value="
									(input) => {
										shipmentDetail.shipment_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							v-if="showMcpRecdDate"
							md="3"
							sm="12"
						>
							<!-- vif customer recd or mcp red -->
							<!-- also need customer pod yn -->
							<m-text-input
								id="mcp-rec-date"
								label="Received Date"
								type="date"
								:value="shipmentDetail.received_date"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Received Date"
								@update-value="
									(input) => {
										shipmentDetail.received_date = input;
									}
								"
							/>
						</b-colxx>
						<b-colxx
							v-if="showCustRecdDate"
							md="3"
							sm="12"
						>
							<m-text-input
								id="cust-rec-date"
								label="Customer Received Date"
								type="date"
								:value="shipmentDetail.customer_received_date"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Customer Received Date"
								@update-value="
									(input) => {
										shipmentDetail.customer_received_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							v-if="showCustPoDYn"
							md="3"
							sm="12"
						>
							<b-form-group
								id="cust-pod"
								label="Customer POD"
								label-for="customer-pod-yn"
								class="field-label-form-group mt-2"
							>
								<b-form-checkbox
									id="customer-pod-yn"
									switch
									class="ml-5"
									:disabled="!permissions.includes('CanEditLogisticsRecord')"
									v-model="
										shipmentDetail.customer_proof_of_delivery_received_yn
									"
								></b-form-checkbox>
							</b-form-group>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="invoice-amount"
								label="Invoice Amount"
								type="text"
								:value="shipmentDetail.shipper_invoice_amount"
								:rules="{ max: 10 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Invoice Amount"
								@update-value="
									(input) => {
										shipmentDetail.shipper_invoice_amount = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="invoice-number"
								label="Shipper Invoice Number"
								type="text"
								:value="shipmentDetail.shipper_invoice_number"
								:rules="{ max: 100 }"
								:alternative="false"
								:group="false"
								:disabled="!permissions.includes('CanEditLogisticsRecord')"
								name="Invoice Number"
								@update-value="
									(input) => {
										shipmentDetail.shipper_invoice_number = input;
									}
								"
							/>
						</b-colxx>
					</b-form-row>

					<b-row
						v-if="editShipmentYN"
						class="my-2"
					>
						<b-colxx
							md="12"
							sm="12"
						>
							<div
								class="card-header row bg-primary text-white py-2 align-items-center"
								variant="primary"
							>
								<span
									class="inline"
									v-b-toggle.collapse-notes
								>
									Notes
									<i class="fas fa-circle-caret-up when-opened inline ml-2" />
									<i class="fas fa-circle-caret-down when-closed inline ml-2" />
								</span>
								<span
									pill
									variant="primary"
									class="badge bg-info text-white ml-5"
									>{{ noteCount }}</span
								>
								<div class="flex-column ml-auto">
									<i
										v-if="
											permissions.includes('CanCreateLogisticsRecord') ||
											permissions.includes('CanEditLogisticsRecord')
										"
										class="fas fa-square-plus icon-pointer mr-2"
										@click="showAddNoteModal = true"
									/><span class="d-inline text-sm">New Note</span>
								</div>
							</div>
							<b-collapse
								id="collapse-notes"
								class="mt-2"
							>
								<b-table
									id="notes-table"
									ref="__notesTable"
									responsive
									head-variant="dark"
									:items="shipmentDetail.notes"
									:fields="notes_fields"
								>
									<template #cell(created_at)="row">
										{{ new Date(row.item.created_at).toLocaleString() }}
									</template>
								</b-table>
							</b-collapse>
						</b-colxx>
					</b-row>

					<b-row
						v-if="editShipmentYN"
						class="my-2"
					>
						<b-colxx
							md="12"
							sm="12"
						>
							<div
								class="card-header row bg-warning text-white py-2 align-items-center"
								variant="primary"
							>
								<span
									class="inline"
									v-b-toggle.collapse-activity
								>
									Activities
									<i class="fas fa-circle-caret-up when-opened inline ml-2" />
									<i class="fas fa-circle-caret-down when-closed inline ml-2" />
								</span>
								<span
									pill
									variant="primary"
									class="badge bg-info ml-5 text-white"
									>{{ activityCount }}</span
								>
								<div class="flex-column ml-auto">
									<i
										v-if="
											permissions.includes('CanCreateLogisticsRecord') ||
											permissions.includes('CanEditLogisticsRecord')
										"
										class="fas fa-square-plus icon-pointer mr-2"
										@click="addActivity()"
									/><span class="d-inline text-sm">New Activity</span>
								</div>
							</div>
							<b-collapse
								id="collapse-activity"
								class="mt-2"
							>
								<b-table
									id="notes-table"
									ref="__notesTable"
									responsive
									head-variant="dark"
									:items="shipmentDetail.activities"
									:fields="activity_fields"
								>
									<template #cell(due_date)="row">
										{{ new Date(row.item.due_date).toLocaleString() }}
									</template>
									<template #cell(reminder_date)="row">
										{{ new Date(row.item.reminder_date).toLocaleString() }}
									</template>
								</b-table>
							</b-collapse>
						</b-colxx>
					</b-row>

					<m-tracking-info
						:created-by-name="shipmentDetail.created_by_name"
						:created-at="shipmentDetail.created_at"
						:modified-by-name="shipmentDetail.modified_by_name"
						:modified-at="shipmentDetail.modified_at"
					/>

					<template #modal-footer="{}">
						<div v-if="!shipmentSaveComplete">
							<b-button
								v-if="
									permissions.includes('CanCreateLogisticsRecord') ||
									permissions.includes('CanEditLogisticsRecord')
								"
								class="mr-2"
								variant="info"
								:disabled="isSaving || !observer.valid"
								@click="saveShipment()"
							>
								{{ saveNoteButtonLabel }}
							</b-button>
						</div>

						<b-button
							variant="warning"
							:disabled="isSaving"
							@click="closeShipment()"
							>Close</b-button
						>
					</template>
				</b-modal>
			</validation-observer>
		</div>

		<!--  -->
		<!-- Activity Modal -->
		<!--  -->
		<div id="activity-modal-div">
			<validation-observer
				ref="__validateActivityForm"
				v-slot="observer"
			>
				<b-modal
					id="activity-modal"
					v-model="showAddActivityModal"
					title="Add New Activity"
					centered
					scrollable
					no-close-on-esc
					no-close-on-backdrop
					hide-header-close
					header-bg-variant="info"
					header-text-variant="white"
					size="lg"
				>
					<b-alert
						class="my-2"
						:variant="activityAlertVariant"
						:show="activityAlert"
					>
						{{ activityAlertMessage }}
					</b-alert>
					<b-form-row>
						<b-colxx
							md="6"
							sm="12"
						>
							<validation-provider
								rules="required"
								name="Assign To"
								v-bind="$attrs"
								v-slot="{ errors }"
							>
								<label class="field-label-form-group mt-2"
									>Follow Up Assigned To *</label
								>
								<b-form-select
									v-model="activityData.activity_assign_to"
									:options="userList"
									label-field="'Assign Activity To'"
									:required="true"
									value-field="user_id"
									text-field="full_name"
									class="custom-input-select form-control"
								/>
								<div
									v-if="errors[0]"
									class="invalid-feedback text-xs"
									style="display: inline-block"
								>
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-colxx>
						<b-colxx
							v-if="showOutlookTaskCheckbox"
							md="3"
							sm="12"
						>
							<b-form-group
								id="create-outlook-task-fieldset"
								label="Create Outlook Task Reminder?"
								label-for="create--outlook-task-yn"
								class="field-label-form-group mt-2"
							>
								<b-form-checkbox
									id="create-outlook-task-yn"
									v-model="activityData.activity_create_outlook_task_yn"
								></b-form-checkbox>
							</b-form-group>
						</b-colxx>
					</b-form-row>

					<b-form-row>
						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="reminder-date"
								label="Reminder Date"
								:value="activityData.activity_reminder_date"
								:alternative="false"
								:rules="{ required: true }"
								type="datetime-local"
								:group="false"
								name="Reminder Date"
								@update-value="
									(input) => {
										activityData.activity_reminder_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="3"
							sm="12"
						>
							<m-text-input
								id="due-date"
								label="Due Date"
								:value="activityData.activity_due_date"
								:alternative="false"
								:rules="{ required: true }"
								type="datetime-local"
								:group="false"
								name="Due Date"
								@update-value="
									(input) => {
										activityData.activity_due_date = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							md="6"
							sm="12"
						>
							<m-text-input
								id="task-subject"
								label="Subject"
								:value="activityData.activity_task_subject"
								:rules="{ required: true }"
								:alternative="false"
								:group="false"
								name="Subject"
								@update-value="
									(input) => {
										activityData.activity_task_subject = input;
									}
								"
							/>
						</b-colxx>

						<b-colxx
							v-if="activityData.activity_create_outlook_task_yn"
							md="12"
							sm="12"
						>
							<validation-provider
								rules="required"
								name="Choose a task list"
								v-bind="$attrs"
								v-slot="{ errors }"
							>
								<label class="field-label-form-group mt-2"
									>Outlook To Do List *</label
								>
								<b-form-select
									v-model="activityData.outlook_to_do_list"
									:options="outlookToDoListOptions"
									label-field="'Outlook To Do List'"
									:required="true"
									value-field="id"
									text-field="displayName"
									class="custom-input-select form-control"
								/>
								<div
									v-if="errors[0]"
									class="invalid-feedback text-xs"
									style="display: inline-block"
								>
									{{ errors[0] }}
								</div>
							</validation-provider>
						</b-colxx>

						<b-colxx
							md="12"
							sm="12"
						>
							<m-text-area
								id="follow-up-note"
								:value="activityData.activity_follow_up_note"
								name="Task Notes"
								:rows="7"
								:rules="{ required: false, max: 4000 }"
								label="Task Notes"
								:alternative="false"
								:group="false"
								@update-value="
									(input) => {
										activityData.activity_follow_up_note = input;
									}
								"
							/>
						</b-colxx>
					</b-form-row>
					<template #modal-footer="{}">
						<div v-if="!shipmentSaveComplete">
							<b-button
								class="mr-2"
								variant="info"
								:disabled="activityIsSaving || !observer.valid"
								@click="saveActivity()"
							>
								{{ saveActivityButtonLabel }}
							</b-button>
						</div>

						<b-button
							variant="warning"
							:disabled="isSaving"
							@click="closeActivity()"
							>Close</b-button
						>
					</template>
				</b-modal>
			</validation-observer>
		</div>

		<!--  -->
		<!-- Note Modal -->
		<!--  -->
		<div id="note-modal-div">
			<b-modal
				id="shipment-note-modal"
				v-model="showAddNoteModal"
				title="Add New Note"
				centered
				scrollable
				no-close-on-esc
				no-close-on-backdrop
				hide-header-close
				header-bg-variant="info"
				header-text-variant="white"
				size="lg"
			>
				<b-row>
					<b-col
						><m-text-area
							id="shipment-note"
							:value="addNote.note"
							name="Notes"
							:rows="7"
							:rules="{ required: true, max: 4000 }"
							labels="Notes"
							:alternative="false"
							:group="false"
							@update-value="(input) => (addNote.note = input)"
						/>
					</b-col>
				</b-row>
				<template #modal-footer="{}">
					<div v-if="!shipmentSaveComplete">
						<b-button
							class="mr-2"
							variant="info"
							:disabled="isSaving"
							@click="saveNote()"
						>
							{{ saveNoteButtonLabel }}
						</b-button>
					</div>

					<b-button
						variant="warning"
						:disabled="isSaving"
						@click="closeNote()"
						>Close</b-button
					>
				</template>
			</b-modal>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from "vuex";
	import django_session from "@/api/django_session";

	import { shipmentStatus } from "@/constants/shipment_status";
	import { updateWithResponseData } from "@/functions/common";
	import { shipmentObj } from "@/constants/objects/shipment";
	import {
		activityObj,
		activityStatusList,
	} from "@/constants/objects/activity";

	export default {
		name: "LogisticsHome",
		components: {},

		data() {
			return {
				shipmentStatus,
				shipmentObj,
				activityObj,
				activityStatusList,
				showAlert: false,
				alertVariant: "",
				alertMessage: "",

				filterShipmentType: null,
				filterHideClosed: true,

				shipperList: [],
				shipmentList: [],
				shipment_list_fields: [
					{
						key: "actions",
						label: "",
					},
					{
						key: "shipment_type_name",
						label: "Type",
					},
					{
						key: "shipper_name",
						label: "Shipper",
					},
					{
						key: "shipment_status",
						label: "Status",
					},
					{
						key: "mcp_po_number",
						label: "PO #",
					},
					{
						key: "supplier_name",
						label: "Supplier",
					},
					{
						sortable: true,
						key: "estimated_delivery_date",
						label: "Est. Delivery",
					},
					{
						key: "quote_amount",
						label: "Quote",
					},
					{
						key: "shipper_reference_number",
						label: "Ref #",
					},
					{
						key: "shipper_invoice_number",
						label: "Invoice",
					},
					{
						key: "shipper_invoice_amount",
						label: "Invoice",
					},
					{
						key: "received_date",
						label: "Received Date",
					},
				],

				notes_fields: [
					{
						key: "note",
						label: "Notes",
					},
					{
						key: "created_by_name",
						label: "Author",
					},
					{
						key: "created_at",
						label: "Date",
					},
				],

				current_page: 1,
				per_page: 10,
				total_rows: null,
				prev_page_link: "",
				next_page_link: "",

				shipmentTableBusy: false,

				editShipmentYN: false,

				shipmentDetail: {},
				shipmentId: null,
				shipmentIndex: null,
				supplierList: [],

				showShipmentModal: false,

				showAddNoteModal: false,
				addNote: {
					note: "",
				},

				showAddActivityModal: false,
				activityData: {},
				activity_fields: [
					{
						key: "status",
						label: "Status",
					},
					{
						key: "subject",
						label: "Subject",
					},
					{
						key: "assigned_to_name",
						label: "Assigned To",
					},
					{
						key: "due_date",
						label: "Due Date",
					},
					{
						key: "reminder_date",
						label: "Reminder Date",
					},
				],
				userList: [],
				activityIsSaving: false,
				activityAlert: false,
				activityAlertVariant: "",
				activityAlertMessage: "",

				isSaving: false,
				shipmentSaveComplete: false,

				searchCustomerResults: [],
				noCustomerProfileRecordExists: false,
				sageCustomerId: null,

				showTemporaryAlert: false,
				temporaryAlertMessage: "",

				searchStatus: null,
				searchShipper: null,
				searchType: null,
			};
		},

		computed: {
			...mapGetters("auth", ["user", "outlookToDoListOptions", "permissions"]),
			...mapGetters("common", ["currentPage", "entityList", "showLoader"]),

			pageTotal() {
				var page_total = 1;
				page_total = Math.ceil(this.total_rows / this.per_page);
				return page_total;
			},

			shipmentEntityTypes() {
				let list = this.entityList;
				let shipment = list.find(
					(x) => x.entity_type_class_name === "shipment"
				);
				return shipment.type_codes;
			},

			filteredShipmentList() {
				let list = this.shipmentList;
				let filteredList = list;
				if (this.filterHideClosed === true) {
					filteredList = list.filter((x) => x.shipment_status !== "Closed");
				}
				if (this.filterShipmentType !== null) {
					filteredList = list.filter(
						(x) => x.shipment_type === this.filterShipmentType
					);
					return filteredList;
				}
				return filteredList;
			},

			saveNoteButtonLabel() {
				if (this.isSaving) {
					return "Saving...";
				}
				return "Save";
			},

			showSupplierField() {
				if (
					this.shipmentDetail.shipment_type_name === "Inbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showCustomerField() {
				if (
					this.shipmentDetail.shipment_type_name === "Outbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showMcpPoField() {
				if (
					this.shipmentDetail.shipment_type_name === "Inbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showMcpSoField() {
				if (
					this.shipmentDetail.shipment_type_name === "Outbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showMcpRecdDate() {
				if (
					this.shipmentDetail.shipment_type_name === "Inbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showCustRecdDate() {
				if (
					this.shipmentDetail.shipment_type_name === "Outbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showCustPoDYn() {
				if (
					this.shipmentDetail.shipment_type_name === "Outbound" ||
					this.shipmentDetail.shipment_type_name === "Drop"
				) {
					return true;
				}
				return false;
			},

			showOutlookTaskCheckbox() {
				if (this.activityData.activity_assign_to === this.user.user_id) {
					return true;
				}
				return false;
			},

			saveActivityButtonLabel() {
				if (this.activityIsSaving) {
					return "Saving...";
				}
				return "Save";
			},

			noteCount() {
				if (this.shipmentDetail.notes) {
					return this.shipmentDetail.notes.length;
				}
				return 0;
			},

			activityCount() {
				if (this.shipmentDetail.activities) {
					return this.shipmentDetail.activities.length;
				}
				return 0;
			},
		},

		created() {},

		async mounted() {
			this.$store.dispatch("common/showLoader", true);
			document.title = "Logistics Home";

			this.getShipmentList();

			await django_session
				.get("erp/product/supplier/list/", {
					params: {
						page_size: 0,
					},
				})
				.then((response) => {
					this.supplierList = response.data;
				});

			await django_session
				.get("erp/product/shipper/list/", {
					params: {
						page_size: 0,
					},
				})
				.then((response) => {
					this.shipperList = response.data;
				});

			this.$store.dispatch("common/showLoader", false);
		},

		methods: {
			async getShipmentList() {
				await django_session
					.get("erp/product/shipment/list/", {
						params: {
							page: this.current_page,
							searchStatus: this.searchStatus,
							searchShipper: this.searchShipper,
							searchType: this.searchType,
						},
					})
					.then((response) => {
						this.shipmentList = response.data.results;
						this.current_page = response.data.page;
						this.total_rows = response.data.count;
						this.next_page_link = response.links.next;
						this.prev_page_link = response.links.previous;
					})
					.catch((error) => {
						console.log(error.response);
					});
			},

			clearSearch() {
				this.searchType = null;
				this.searchShipper = null;
				this.searchStatus = "";
				this.current_page = 1;
				this.shipmentList = [];
				this.getShipmentList();
			},

			async createShipment() {
				this.editShipmentYN = false;
				this.shipmentDetail = JSON.parse(JSON.stringify(shipmentObj));
				this.shipmentDetail.shipment_status = "New";
				this.showShipmentModal = true;
			},

			closeShipment() {
				this.showShipmentModal = false;
				this.editShipmentYN = null;
				this.showAlert = false;
				this.alertMessage = "";
				this.alertVariant = "";
				this.shipmentId = null;
				this.shipmentIndex = false;
				this.searchCustomerResults = [];
				this.shipmentDetail.customer_name = "";
				this.noCustomerProfileRecordExists = false;
				this.shipmentSaveComplete = false;
				this.isSaving = false;
			},

			getShipmentType() {
				// let shipmentType = this.shipmentDetail.shipment_type
				let shipmentTypeObj = this.shipmentEntityTypes.find(
					(x) => x.entity_type_code_id === this.shipmentDetail.shipment_type
				);
				this.shipmentDetail.shipment_type_name = shipmentTypeObj.type_label;
			},

			async saveShipment() {
				this.shipmentTableBusy = true;
				this.isSaving = true;

				if (this.editShipmentYN === false) {
					await django_session
						.post("erp/product/shipment/create/", {
							no_customer_profile_exists: this.noCustomerProfileRecordExists,
							sage_customer_id: this.sageCustomerId,
							shipment_type: this.shipmentDetail.shipment_type,
							shipment_status: this.shipmentDetail.shipment_status,
							supplier: this.shipmentDetail.supplier,
							customer: this.shipmentDetail.customer,
							mcp_po_number: this.shipmentDetail.mcp_po_number,
							shipper: this.shipmentDetail.shipper,
							shipper_reference_number:
								this.shipmentDetail.shipper_reference_number,
							quote_amount: this.shipmentDetail.quote_amount,
							quote_approved_yn: this.shipmentDetail.quote_approved_yn,
							estimated_delivery_date:
								this.shipmentDetail.estimated_delivery_date,
							received_date: this.shipmentDetail.received_date,
							pickup_date: this.shipmentDetail.pickup_date,
							loading_date: this.shipmentDetail.loading_date,
							shipment_date: this.shipmentDetail.shipment_date,
							shipper_invoice_number:
								this.shipmentDetail.shipper_invoice_number,
							shipper_invoice_amount:
								this.shipmentDetail.shipper_invoice_amount,
							mcp_so_number: this.shipmentDetail.mcp_so_number,
							customer_po_number: this.shipmentDetail.customer_po_number,
							customer_received_date:
								this.shipmentDetail.customer_received_date,
							customer_proof_of_delivery_received_yn:
								this.shipmentDetail.customer_proof_of_delivery_received_yn,
							created_by: this.user.user_id,
							modified_by: this.user.user_id,
						})
						.then((response) => {
							console.log(response.data);
							this.shipmentList.unshift(response.data);

							this.alertMessage = "Shipment record created.";
							this.alertVariant = "success";
							this.showAlert = true;

							this.shipmentTableBusy = false;
						})
						.catch((error) => {
							console.log(error.response.data);
						});
					this.isSaving = false;
					this.shipmentSaveComplete = true;
				}

				if (this.editShipmentYN === true) {
					await django_session
						.patch("erp/product/shipment/" + this.shipmentId + "/", {
							shipment_type: this.shipmentDetail.shipment_type,
							shipment_status: this.shipmentDetail.shipment_status,
							supplier: this.shipmentDetail.supplier,
							mcp_po_number: this.shipmentDetail.mcp_po_number,
							shipper: this.shipmentDetail.shipper,
							shipper_reference_number:
								this.shipmentDetail.shipper_reference_number,
							quote_amount: this.shipmentDetail.quote_amount,
							quote_approved_yn: this.shipmentDetail.quote_approved_yn,
							estimated_delivery_date:
								this.shipmentDetail.estimated_delivery_date,
							received_date: this.shipmentDetail.received_date,
							pickup_date: this.shipmentDetail.pickup_date,
							loading_date: this.shipmentDetail.loading_date,
							shipment_date: this.shipmentDetail.shipment_date,
							shipper_invoice_number:
								this.shipmentDetail.shipper_invoice_number,
							shipper_invoice_amount:
								this.shipmentDetail.shipper_invoice_amount,
							modified_by: this.user.user_id,
						})
						.then((response) => {
							let updateData = updateWithResponseData(
								this.shipmentDetail,
								this.shipmentList,
								this.shipmentIndex,
								response.data,
								false
							);
							this.shipmentDetail = updateData.updatedObject;
							this.shipmentList = updateData.updatedList;

							this.alertMessage = "Shipment record updated.";
							this.alertVariant = "success";
							this.showAlert = true;

							this.shipmentTableBusy = false;
						})
						.catch((error) => {
							console.log(error.response.data);
						});
					this.isSaving = false;
					this.shipmentSaveComplete = true;
				}
			},

			async searchCustomer() {
				// console.log("Search Customer");
				await django_session
					.get("erp/customer/search/", {
						params: {
							keyword: this.shipmentDetail.customer_name,
							hide_inactive_yn: true,
							hide_prospects_yn: true,
							page_size: 0,
						},
					})
					.then((response) => {
						this.searchCustomerResults = response.data;
					});
			},

			selectCustomer(e) {
				console.log("select customer", e);
				let find_customer_id = this.searchCustomerResults.find(
					(x) => x.customer_name === e.trim()
				);
				if (find_customer_id === undefined) {
					this.showTemporaryAlert = true;
					this.temporaryAlertMessage =
						"Please try again. You must select a customer from the list.";
				}
				console.log(find_customer_id);
				this.noCustomerProfileRecordExists = false;
				this.shipmentDetail.customer = find_customer_id.erp_customer_id;
				this.showTemporaryAlert = false;
				this.temporaryAlertMessage = "";
			},

			openNote(item, index) {
				this.addNote.note = "";
				this.shipmentId = item.shipment_id;
				this.shipmentDetail = item;
				if (this.shipmentDetail.notes === undefined) {
					Object.assign(this.shipmentDetail, { notes: [] });
				}
				this.shipmentIndex = index;
				this.showAddNoteModal = true;
			},

			async saveNote() {
				this.isSaving = true;
				django_session
					.post("erp/product/shipment-note/create/", {
						note: this.addNote.note,
						shipment: this.shipmentId,
						created_by: this.user.user_id,
						modified_by: this.user.user_id,
					})
					.then((response) => {
						this.shipmentDetail.notes.unshift(response.data);
						this.isSaving = false;
						this.showAddNoteModal = false;
						this.alertMessage = "Note saved.";
						this.alertVariant = "success";
						this.showAlert = true;
					});
			},

			async goShipmentDetail(item, index) {
				this.editShipmentYN = true;
				this.shipmentIndex = index;
				this.shipmentId = item.shipment_id;

				await django_session
					.get("erp/product/shipment/" + this.shipmentId + "/")
					.then((response) => {
						this.shipmentDetail = response.data;
					});
				this.showShipmentModal = true;
			},

			closeNote() {
				this.showAddNoteModal = false;
			},

			async addActivity() {
				this.activityData = JSON.parse(JSON.stringify(activityObj));
				await django_session
					.get("erp/user/list/", {
						// params: {
						//   page: 0,
						// },
					})
					.then((response) => {
						this.userList = response.data.results;
						this.activityData.activity_assign_to = this.user.user_id;
						if (this.user.default_to_do_list !== "") {
							this.activityData.outlook_to_do_list =
								this.user.default_to_do_list;
						}
						this.showAddActivityModal = true;
					});
			},

			async saveActivity() {
				this.activityIsSaving = true;
				this.activityData.status = "Open";
				let reminderUTC = new Date(
					this.activityData.activity_reminder_date
				).toISOString();
				let dueDateUTC = new Date(
					this.activityData.activity_due_date
				).toISOString();
				// console.log(reminderUTC, dueDateUTC)
				await django_session
					.post("erp/activity/create/", {
						owning_entity_id: this.shipmentId,
						entity_type_name: "shipment",
						assigned_to: this.activityData.activity_assign_to,
						status: this.activityData.status,
						subject: this.activityData.activity_task_subject,
						detail: this.activityData.activity_follow_up_note,
						due_date: dueDateUTC,
						reminder_date: reminderUTC,
						// due_date: new Date(this.activityData.activity_due_date).toISOString(),
						// reminder_date: new Date(this.activityData.activity_reminder_date).toISOString(),
						create_outlook_task_yn:
							this.activityData.activity_create_outlook_task_yn,
						outlook_list_id: this.activityData.outlook_to_do_list,
					})
					.then((response) => {
						console.log(response.data);
						this.shipmentDetail.activities.unshift(response.data);
						this.activityAlertMessage = "Activity saved.";
						this.activityAlertVariant = "success";
						this.activityAlert = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.activityAlertMessage = "Error saving activity.";
						this.activityAlertVariant = "danger";
						this.activityAlert = true;
					});
				this.activityIsSaving = false;
			},

			closeActivity() {
				this.showAddActivityModal = false;
				this.activityData = {};
				this.activityAlert = false;
				this.activityAlertVariant = "";
				this.activityAlertMessage = "";
			},
		},
	};
</script>

<style lang="scss" scoped>
	span {
		display: block;
	}

	.warning-message {
		color: fuchsia;
		font-size: 0.5rem;
		margin-top: 3px;
	}

	.collapsed > .when-opened,
	:not(.collapsed) > .when-closed {
		display: none;
	}
</style>
