export const activityStatusList = ["Open", "In Progress", "Closed"]

export const activityObj = {
  activity_follow_up_yn: false,
  activity_assign_to: null,
  activity_create_outlook_task_yn: false,
  activity_reminder_date: null,
  activity_due_date: null,
  activity_task_subject: "",
  outlook_to_to_list: null,
  activity_follow_up_note: "",
}
